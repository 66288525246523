.kdgm-responsive-player-gemist {
    position: relative;
    padding-bottom: 40%; /* 75% voor 4:3 video  */
    height: 0;
    max-height: 600px;
    min-height: 500px;
    overflow: hidden;
    opacity: 1;
}

.kdgm-responsive-player-gemist iframe {
    position: absolute; 
    top:0;
    left: 0;
    width: 100%;

    min-height: 450px;
    opacity: 1;
}
  
.kdgm-responsive-player {
    position: relative;
    padding-bottom: 56.25%; /* 75% voor 4:3 video */
    height: 0;
    overflow: hidden;
    opacity: 1;
}

.kdgm-responsive-player iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
}
   
 iframe{max-width: 100%}