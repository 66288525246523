.wp-block-gallery:after {content:"";display:table;clear:both;}

img.aligncenter, div.aligncenter, figure.aligncenter, img.wp-post-image {display:block; margin:1em auto;}
img.alignright, div.alignright, figure.alignright {float:right; margin:1em 0 1em 2em;}
img.alignleft, div.alignleft, figure.alignleft, img.wp-post-image.attachment-thumb {float:left; margin:1em 1em 2em 0;}
figure {max-width: 100%; height: auto; margin:1em 0; display: block;}

p img.alignright, p img.alignleft {margin-top:0;}


.wp-block-gallery figure img {max-width:100%; height:auto; margin:0 auto; display:block;}


.wp-block-gallery figure {float:left; margin:0 2% 1em 0;;}


.wp-block-gallery.has-nested-images.columns-1 figure {width:100%; margin:0 0 1em 0; float:none;}


.wp-block-gallery.has-nested-images.columns-3 figure {width:32%;}
.wp-block-gallery.has-nested-images.columns-3 figure:nth-of-type(3n+3) {margin-right:0;}
.wp-block-gallery.has-nested-images.columns-3 figure:nth-of-type(3n+4) {clear:left;}


.wp-block-gallery.has-nested-images.columns-2 figure {width:49%;}
.wp-block-gallery.has-nested-images.columns-2 figure:nth-of-type(even) {margin-right:0;}
.wp-block-gallery.has-nested-images.columns-2 figure:nth-of-type(odd) {clear:left;}


.wp-block-gallery.has-nested-images.columns-4 figure {width:23.25%;}
.wp-block-gallery.has-nested-images.columns-4 figure:nth-of-type(4n+4) {margin-right:0;}
.wp-block-gallery.has-nested-images.columns-4 figure:nth-of-type(4n+5) {clear:left;}


.wp-block-gallery.has-nested-images.columns-5 figure {width:18%;}
.wp-block-gallery.has-nested-images.columns-5 figure:nth-of-type(5n+5) {margin-right:0;}
.wp-block-gallery.has-nested-images.columns-5 figure:nth-of-type(5n+6) {clear:left;}


.wp-block-gallery.has-nested-images.columns-6 figure {width:14.2%;}
.wp-block-gallery.has-nested-images.columns-6 figure:nth-of-type(6n+6) {margin-right:0;}
.wp-block-gallery.has-nested-images.columns-6 figure:nth-of-type(6n+7) {clear:left;}


.wp-block-gallery.has-nested-images.columns-7 figure {width:12%;}
.wp-block-gallery.has-nested-images.columns-7 figure:nth-of-type(7n+7) {margin-right:0;}
.wp-block-gallery.has-nested-images.columns-7 figure:nth-of-type(7n+8) {clear:left;}


.wp-block-gallery.has-nested-images.columns-8 figure {width:10.2%;}
.wp-block-gallery.has-nested-images.columns-8 figure:nth-of-type(8n+8) {margin-right:0;}
.wp-block-gallery.has-nested-images.columns-8 figure:nth-of-type(8n+9) {clear:left;}


.wp-block-gallery.has-nested-images.columns-9 figure {width:8.85%;}
.wp-block-gallery.has-nested-images.columns-9 figure:nth-of-type(9n+9) {margin-right:0;}
.wp-block-gallery.has-nested-images.columns-9 figure:nth-of-type(9n+10) {clear:left;}


@media (max-width:767px) {
	img.alignright, div.alignright, figure.alignright, img.alignleft, div.alignleft, figure.alignleft, img.wp-post-image.attachment-thumb {display:block; margin:1em auto; float:none;}
	.wp-block-gallery.wp-block-gallery-columns-4 figure, .wp-block-gallery.wp-block-gallery-columns-5 figure, .wp-block-gallery.wp-block-gallery-columns-6 figure, .wp-block-gallery.wp-block-gallery-columns-7 figure, .wp-block-gallery.wp-block-gallery-columns-8 figure, .wp-block-gallery.wp-block-gallery-columns-9 figure {width:100%; margin:0 0 1em 0; float:none;}
}


@media (max-width: 768px) {
  .blocks-gallery-grid .blocks-gallery-image, .blocks-gallery-grid .blocks-gallery-item, .wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
      width: 100%;
  }
}

.wp-block-gallery.has-nested-images {
   /*display: flex;*/
  /* flex-wrap: wrap; */
}

.wp-block-gallery__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.grid-gallery {
    --g: 6px; 
    
    display: grid !important;
    width: 500px; 
    aspect-ratio: 1;
    grid: auto-flow 1fr/repeat(3,1fr);
    gap: var(--g) !important;
  }
  .grid-gallery .wp-block-image {
    width: 100% !important;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: .5s
  }
  .grid-gallery .wp-block-image:nth-child(2) {
    grid-area: 1/2/span 2/span 2;
    clip-path: polygon(0 0,100% 0,100% 100%,calc(50% + var(--g)/4) 100%,0 calc(50% - var(--g)/4))
  }
  .grid-gallery .wp-block-image:nth-child(3) {
    grid-area: 2/1/span 2/span 2;
    clip-path: polygon(0 0,calc(50% - var(--g)/4) 0,100% calc(50% + var(--g)/4),100% 100%,0 100%);
  }
  .grid-gallery .wp-block-image:nth-child(1), .grid-gallery .wp-block-image:nth-child(4) {
    height: 164px !important;
  }
    @media only screen and (max-width: 600px) {
    .grid-gallery {
      display: flex !important;
      width: 100% !important;
    }
    .grid-gallery .wp-block-image:nth-child(2), .grid-gallery .wp-block-image:nth-child(3){
      grid-area: none;
      clip-path: none;
    }
  }
